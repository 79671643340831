<template>
  <div>
    
  </div>
</template>

<script>

  export default {
    name: 'GroupTag',
    components: {},

    data () {
      return {

      };
    },

    computed: {},

    mounted() {},

    methods: {},


  }

</script>
<style  scoped>

</style>